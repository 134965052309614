/* istanbul ignore file */
import {
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import UpdateChatbotDrawer from '@/components/update-chatbot-drawer';
import { Chatbot, Website } from '@/services/hubbot/types';
import UpdateWebsiteDrawer from '@/components/update-website-drawer';

/**
 * Column definition for ContactListsDataGrid
 * @see https://mui.com/x/react-data-grid/column-definition/
 */
export const defaultColDef: GridColDef[] = [
  {
    field: 'inProd',
    headerName: 'Estado',
    width: 75,
    editable: false,
    renderCell: (params: GridRenderCellParams): JSX.Element => {
      return (
        <Stack style={{ width: '100%' }} direction="column">
          <Chip
            label={params.value ? 'PROD' : 'DEMO'}
            size="small"
            color={params.value ? 'primary' : 'secondary'}
            sx={{ fontWeight: 'bold', color: 'white' }}
          />
        </Stack>
      );
    },
  },
  {
    field: 'chatbotId',
    headerName: 'ChatbotId',
    type: 'string',
    minWidth: 160,
    editable: false,
  },
  {
    field: 'websiteName',
    headerName: 'Nombre',
    type: 'string',
    minWidth: 220,
    editable: false,
    renderCell: (params: {
      row: {
        websites: Website[];
      };
    }): JSX.Element => {
      return (
        <Stack direction="column" spacing={2}>
          {params.row.websites.map((website) => {
            return (
              <Stack key={website.websiteId} direction="row" spacing={1}>
                <Typography variant="body1">{website.name}</Typography>
              </Stack>
            );
          })}
        </Stack>
      );
    },
  },
  {
    field: 'websiteId',
    headerName: 'WebsiteId',
    type: 'string',
    minWidth: 240,
    editable: false,
    renderCell: (params: {
      row: {
        websites: Website[];
      };
    }): JSX.Element => {
      return (
        <Stack direction="column" spacing={2}>
          {params.row.websites.map((website) => {
            return (
              <Stack key={website.websiteId} direction="row" spacing={1}>
                <Typography variant="body1">{website.websiteId}</Typography>
              </Stack>
            );
          })}
        </Stack>
      );
    },
  },
  {
    field: 'companyId',
    headerName: 'CompanyId',
    type: 'string',
    minWidth: 240,
    editable: false,
    renderCell: (params: {
      row: {
        websites: Website[];
      };
    }): JSX.Element => {
      return (
        <Stack direction="column" spacing={2}>
          {params.row.websites.map((website) => {
            return (
              <Stack key={website.websiteId} direction="row" spacing={1}>
                <Typography variant="body1">{website.companyId}</Typography>
              </Stack>
            );
          })}
        </Stack>
      );
    },
  },

  {
    field: 'actions',
    headerName: 'Acciones',
    minWidth: 120,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: {
      row: {
        websites: Website[];
      };
    }): JSX.Element => {
      return (
        <Stack direction="row" spacing={0}>
          <Stack direction="column" spacing={0}>
            {params.row.websites.map((website) => {
              return (
                <Stack key={website.websiteId}>
                  <Tooltip
                    title={
                      <Typography variant={'caption'}>Probar chat</Typography>
                    }
                  >
                    <Link
                      href={website.widgetUrl}
                      variant="body1"
                      underline="hover"
                      target="_blank"
                    >
                      <IconButton aria-label={'testChat'}>
                        <InsertLinkIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Stack>
              );
            })}
          </Stack>
          <UpdateChatbotDrawer chatbot={params.row as Chatbot} />
          <UpdateWebsiteDrawer chatbot={params.row as Chatbot} />
        </Stack>
      );
    },
  },
];
