/* istanbul ignore file */

import { HUBBOT_API_URL } from '@/constants/environment';

export const createChatbotUrl = `${HUBBOT_API_URL}/chatbot`;
export const updateChatbotUrl = `${HUBBOT_API_URL}/chatbot`;
export const getChatbotsUrl = `${HUBBOT_API_URL}/chatbot`;
export const getTemplatesUrl = `${HUBBOT_API_URL}/chatbot/templates`;
export const newSimulationUrl = `${HUBBOT_API_URL}/proxy/new-simulation`;
export const assignWebsiteUrl = `${HUBBOT_API_URL}/chatbot/assignwebsite`;
export const deleteWebsiteUrl = `${HUBBOT_API_URL}/chatbot/website`;
