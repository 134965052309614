/* eslint-disable @typescript-eslint/no-unused-vars */
import { Chatbot, CreateChatbotDTO } from './types';
import { assignWebsiteUrl } from './urls';

import { HubbotApiHttpClient } from '@/utils/http-client';
//TO DO CAMBIAR EL TYPE CREATE CHATBOT POR PROPIO
export async function assignWebsite(
  chatbotBody: Partial<CreateChatbotDTO>
): Promise<Chatbot> {
  const client = HubbotApiHttpClient.instance;

  const response = await client.post<Chatbot>(assignWebsiteUrl, chatbotBody);

  return response;
}
