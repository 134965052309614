/* eslint-disable no-console */
import { getTemplatesUrl } from './urls';
import { Template } from './types';

import { HubbotApiHttpClient } from '@/utils/http-client';

export async function getTemplates(): Promise<Template[]> {
  const client = HubbotApiHttpClient.instance;

  const result = await client.get<Template[]>(getTemplatesUrl);

  return result;
}
