/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CreateChatbotForm } from './create-chatbot-form.component';
import {
  rejectedToast,
  requiredFieldsToast,
  successToast,
} from './create-chatbot-toast';

import { createChatbot } from '@/services/hubbot';
import { CreateChatbotDTO, Template } from '@/services/hubbot/types';

interface CreateChatbotDrawerProps {
  onChatbotCreated(): Promise<void> | void;
  templates: Template[];
}

/**
 * The `CreateTemplatenDrawer` provides a button;
 * when it's clicked, it opens a `Drawer` to create a campaign
 * @param {CreateChatbotDrawerProps} props
 */
export const CreateChatbotDrawer = (props: CreateChatbotDrawerProps) => {
  const { onChatbotCreated, templates } = props;

  /** state for open-close drawer */
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chatbot, setChatbot] = useState<CreateChatbotDTO>({
    templateId: '',
    companyId: '',
    chatbotId: '',
    websiteId: '',
  });

  const onFormUpdate = (data: Partial<CreateChatbotDTO>) => {
    setChatbot({
      ...chatbot,
      ...data,
    });
  };

  const toast = useToast();

  const areRequiredFieldsComplete = () => {
    const { templateId, chatbotId, websiteId } = chatbot;

    return templateId && chatbotId && websiteId;
  };

  const validateForm = (): boolean => {
    const requiredFieldsComplete = areRequiredFieldsComplete();

    if (requiredFieldsComplete) {
      return true;
    }
    toast(requiredFieldsToast);
    return false;
  };

  const onFormSubmit = async (): Promise<void> => {
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        await createChatbot(chatbot);

        onClose();
        await onChatbotCreated();
        toast(successToast);
      } catch (e) {
        toast(rejectedToast);
      }

      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (templates.length) {
      setChatbot({
        ...chatbot,
        templateId: templates[0].templateId,
      });
    }
  }, [templates]);

  return (
    <>
      <Button
        // isDisabled
        variant={'primary'}
        aria-label={'Create Chatbot'}
        onClick={onOpen}
        data-testid={'create_chatbot_button'}
      >
        Crear chatbot
      </Button>
      <Drawer size={'lg'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            textStyle={'h3'}
            borderBottomWidth={'1px'}
            data-testid={'create_chatbot_drawer_header'}
          >
            Crear Chatbot
          </DrawerHeader>

          <DrawerBody>
            <CreateChatbotForm
              templates={templates}
              chatbot={chatbot}
              onFormUpdate={onFormUpdate}
            />
          </DrawerBody>

          <DrawerFooter borderTopWidth={'1px'}>
            <Button
              variant={'outline'}
              colorScheme={'primary'}
              mr={3}
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button
              variant={'primary'}
              data-testid={'submit_button'}
              onClick={async () => {
                await onFormSubmit();
              }}
              isLoading={isSubmitting}
            >
              Crear chatbot
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
