/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormControl, FormLabel, OutlinedInput } from '@mui/material';
import { Stack } from '@mui/system';

import { ReadonlyTextField } from '@/components/form/mui';
import { AssignWebsiteDTO } from '@/services/hubbot/types';

interface UpdateWebsiteFormProps {
  chatbot: AssignWebsiteDTO;
  onFormUpdate(data: Partial<AssignWebsiteDTO>): void;
}

export const UpdateWebsiteForm = ({
  chatbot,
  onFormUpdate,
}: UpdateWebsiteFormProps) => {
  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = event.target;

    onFormUpdate({ [id]: value });
  };

  return (
    <>
      <Stack spacing={2} m={3}>
        <FormControl>
          <FormLabel htmlFor={'chatbotId'}>chatbot-id</FormLabel>
          <ReadonlyTextField
            id="chatbotId"
            value={chatbot.chatbotId}
            sx={{ minWidth: '16rem' }}
          />
        </FormControl>
        <FormControl required>
          <FormLabel htmlFor={'companyId'} required>
            company-id
          </FormLabel>
          <OutlinedInput
            id={'companyId'}
            data-testid={'company_id_input'}
            placeholder={'Ej.: 63652bb66449d3002a5f082d'}
            value={chatbot.companyId}
            onChange={(e) => onValueChange(e)}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor={'websiteId'} required>
            website-id
          </FormLabel>
          <OutlinedInput
            id={'websiteId'}
            data-testid={'website_id_input'}
            placeholder={'Ej.: 655247cc204d8b0032d2d02f'}
            value={chatbot.websiteId}
            onChange={(e) => onValueChange(e)}
            required
          />
        </FormControl>
      </Stack>
    </>
  );
};
