/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import Iframe from 'react-iframe';
import { useParams } from 'react-router-dom';

import { mockedJwt } from '@/__mocks__/services/auth/data';
import { ENVIRONMENT, MOCKED_AUTH } from '@/constants/environment';

export const SimulationReportPAGE = () => {
  const JWT = MOCKED_AUTH ? mockedJwt : Cookies.get('jwt');
  const { id } = useParams<{ id: string }>();
  const url = `https://appsmith.devcliengo.com/app/simulation-report/dashboard-654d7d6f36a60447071ed2df?embed=true&jwt=${JWT}&env=${ENVIRONMENT}&simulationId=${id}`;

  return (
    <Container maxW={'8xl'} minH={'3xl'} p={4}>
      <Iframe
        url={url}
        width="100%"
        height="800"
        id=""
        className=""
        display="inline"
        position="relative"
      />
    </Container>
  );
};
