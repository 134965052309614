import { HStack, Input, Select } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';

const timeOptions = [
  { id: '00:00', value: '00:00' },
  { id: '01:00', value: '01:00' },
  { id: '02:00', value: '02:00' },
  { id: '03:00', value: '03:00' },
  { id: '04:00', value: '04:00' },
  { id: '05:00', value: '05:00' },
  { id: '06:00', value: '06:00' },
  { id: '07:00', value: '07:00' },
  { id: '08:00', value: '08:00' },
  { id: '09:00', value: '09:00' },
  { id: '10:00', value: '10:00' },
  { id: '11:00', value: '11:00' },
  { id: '12:00', value: '12:00' },
  { id: '13:00', value: '13:00' },
  { id: '14:00', value: '14:00' },
  { id: '15:00', value: '15:00' },
  { id: '16:00', value: '16:00' },
  { id: '17:00', value: '17:00' },
  { id: '18:00', value: '18:00' },
  { id: '19:00', value: '19:00' },
  { id: '20:00', value: '20:00' },
  { id: '21:00', value: '21:00' },
  { id: '22:00', value: '22:00' },
  { id: '23:00', value: '23:00' },
];

interface ScheduleDateTimePickerProps {
  initialValue: Date;
  onValueChange(newValue: Date): void;
  minDate?: string;
  maxDate?: string;
}

export const ScheduleDateTimePicker = (props: ScheduleDateTimePickerProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { initialValue, onValueChange, minDate, maxDate, ...additionalProps } =
    props;

  const getDate = (date: Date): string => {
    const dayDate = dayjs(date);

    return dayDate.format('YYYY-MM-DD');
  };

  const getTime = (date: Date): string => {
    const dayDate = dayjs(date);

    return dayDate.format('HH:mm');
  };

  const [value, setValue] = useState<Date>(initialValue);
  const [dateString, setDateString] = useState<string>(getDate(initialValue));
  const [timeString, setTimeString] = useState<string>(getTime(initialValue));

  const buildDate = (date: string, time: string): Date => {
    const dayDate = dayjs(`${date}T${time}`);

    return dayDate.toDate();
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setDateString(value);
    const newValue = buildDate(value, timeString);

    setValue(newValue);
    onValueChange(newValue);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setTimeString(value);
    const newValue = buildDate(dateString, value);

    setValue(newValue);
    onValueChange(newValue);
  };

  return (
    <>
      <HStack {...additionalProps}>
        <Input
          id={'scheduleDate'}
          type={'date'}
          onChange={handleDateChange}
          value={getDate(value)}
          min={minDate}
          max={maxDate}
          data-testid={'schedule_date_input'}
        />
        <Select
          id={'scheduleTime'}
          value={getTime(value)}
          onChange={handleTimeChange}
          data-testid={'schedule_time_select'}
        >
          {timeOptions.map((t) => (
            <option key={t.id} value={t.id}>
              {t.value}
            </option>
          ))}
        </Select>
      </HStack>
    </>
  );
};
