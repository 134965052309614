import { ReactNode } from 'react';

interface ShowProps {
  when: boolean;
  children: ReactNode[] | ReactNode | JSX.Element[] | JSX.Element;
}

/**
 * Component to show `children` if the `when` condition is met
 * @param {ShowProps} props
 * @returns children only if `when` is `true`
 */
export const Show = ({ when, children }: ShowProps) => {
  if (when) {
    return <>{children}</>;
  }

  return null;
};
