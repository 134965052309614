export const ENVIRONMENT: string = process.env.ENVIRONMENT || 'local';

export const APP_NAME: string = process.env.APP_NAME || 'ts-hsm-manager';

export const MOCKED_AUTH: boolean = process.env.MOCKED_AUTH === 'true' || false;

export const FAQS_APP_URL: string = process.env.FAQS_APP_URL || '';

export const LOGIN_REDIRECT_URL: string =
  process.env.LOGIN_REDIRECT_URL || 'https://crm.cliengo.com';

export const CLIENGO_API_URL: string =
  process.env.CLIENGO_API_URL || 'https://api.stagecliengo.com/1.0';

export const HUBBOT_API_URL: string =
  process.env.HUBBOT_API_URL || 'https://hubbot-api.stagecliengo.com';
