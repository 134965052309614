import { Button, Container, useDisclosure } from '@chakra-ui/react';

import SimulationList from './simulations-list';
import SimulationForm from './simulations-form';

export const SimulationsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Container maxW="container.xl" padding={'4'}>
      <Button onClick={onOpen} colorScheme="teal" size="md" mb={4}>
        Nueva Simulación
      </Button>
      <SimulationList />
      <SimulationForm isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};
