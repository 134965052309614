/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import Iframe from 'react-iframe';

import { mockedJwt } from '@/__mocks__/services/auth/data';
import {
  ENVIRONMENT,
  FAQS_APP_URL,
  MOCKED_AUTH,
} from '@/constants/environment';

export const FAQsPage = () => {
  const JWT = MOCKED_AUTH ? mockedJwt : Cookies.get('jwt');
  const url = `${FAQS_APP_URL}?embed=true&jwt=${JWT}&env=${ENVIRONMENT}`;

  console.log(url);

  return (
    <Container maxW={'8xl'} minH={'3xl'} p={4}>
      <Iframe
        url={url}
        width="100%"
        height="800"
        id=""
        className=""
        display="inline"
        position="relative"
      />
    </Container>
  );
};
