import { UseToastOptions } from '@chakra-ui/react';

const baseToast: UseToastOptions = {
  duration: 5000,
  isClosable: true,
};

export const successToast: UseToastOptions = {
  ...baseToast,
  title: 'Chatbot creado',
  description: 'El chatbot se creó correctamente.',
  status: 'success',
};

export const rejectedToast: UseToastOptions = {
  ...baseToast,
  title: 'Error al crear',
  description:
    'Hubo un error al crear el chatbot. Por favor, comuníquese con soporte de Cliengo.',
  status: 'error',
};

export const requiredFieldsToast: UseToastOptions = {
  ...baseToast,
  title: 'Campos requeridos',
  description: 'Por favor, complete todos los campos requeridos (*).',
  status: 'warning',
};
