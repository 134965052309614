/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import {
  Alert,
  Drawer,
  Box,
  Button,
  Divider,
  Snackbar,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { AxiosError } from 'axios';

import { UpdateWebsiteForm } from './update-website-form.component';
import { ListWebsites } from './list-websites-component';

import { getChatbots } from '@/services/hubbot';
import { Chatbot, AssignWebsiteDTO } from '@/services/hubbot/types';
import { assignWebsite } from '@/services/hubbot/assign-website';
import theme from '@/theme/mui';

interface UpdateChatbotDrawerProps {
  chatbot: Chatbot;
}
interface ApiError {
  response: {
    data: {
      message: string;
    };
  };
}

export const UpdateWebsiteDrawer = ({ chatbot }: UpdateChatbotDrawerProps) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [chatbotUpdate, setChatbotUpdate] = React.useState<AssignWebsiteDTO>({
    chatbotId: chatbot.chatbotId,
    websiteId: '',
    companyId: '',
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onFormSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    if (validFields()) {
      try {
        await assignWebsite(chatbotUpdate);
        await getChatbots();
        setOpen(false);
      } catch (e) {
        if (e instanceof AxiosError) {
          const nuevoTipo = e as ApiError;

          if (nuevoTipo.response?.data?.message) {
            toastError(nuevoTipo.response.data.message);
          }
        }
        console.log(e);
      }
    }
    setIsSubmitting(false);
  };
  const validFields = (): boolean => {
    const web = chatbotUpdate.websiteId;
    const comp = chatbotUpdate.companyId;
    const limitC = 19;

    if (web != '' && comp != '') {
      if (web.length > limitC && comp.length > limitC) {
        return true;
      } else {
        toastError('Los campos deben tener 20 caracteres');
        return false;
      }
    } else {
      toastError('Debe completar todos los campos');
      return false;
    }
  };
  const toastError = (message: string) => {
    setError(true);
    setErrorMessage(message);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  const onFormUpdate = (data: AssignWebsiteDTO) => {
    setChatbotUpdate({
      ...chatbotUpdate,
      ...data,
    });
  };

  const openDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={<Typography variant={'caption'}>Asignar Websites</Typography>}
      >
        <IconButton aria-label={'websites'} onClick={openDrawer(true)}>
          <AddToQueueIcon />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={openDrawer(false)}
        PaperProps={{
          sx: { width: '40%' },
        }}
      >
        <Box
          sx={{
            padding: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
          }}
        >
          {/* HEADER */}
          <div
            id="header"
            style={{
              flexShrink: 0,
            }}
          >
            <Stack spacing={1} m={2}>
              <Typography variant={'h2'}>Asignar Websites</Typography>
            </Stack>
            <Divider />
          </div>
          {/* BODY */}

          <div
            id="content"
            style={{
              flexGrow: '1',
              overflow: 'auto',
              minHeight: '2em',
            }}
          >
            <Stack>
              <UpdateWebsiteForm
                chatbot={chatbotUpdate}
                onFormUpdate={onFormUpdate}
              />
            </Stack>
            <Stack spacing={1} m={2}>
              <Typography variant={'h2'}>Websites Asignados</Typography>
            </Stack>

            <Divider />
            <ListWebsites chatbot={chatbot} closeDrawer={closeDrawer} />
          </div>

          {/* FOOTER */}
          <div id="footer">
            <Divider />
            <Stack
              spacing={1}
              m={2}
              direction="row"
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                variant="outlined"
                disabled={isSubmitting}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                data-testid={'submit_button'}
                onClick={() => {
                  void onFormSubmit();
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardando...' : 'Guardar cambios'}
              </Button>
              <Snackbar
                open={error}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                  setError(false);
                }}
              >
                <Alert severity="error" sx={{ width: '100%' }}>
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Stack>
          </div>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};
