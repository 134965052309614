/* eslint-disable @typescript-eslint/unbound-method */
import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'sm',
  },
});
