/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Button,
  Divider,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

import { UpdateChatbotForm } from './update-chatbot-form.component';

import { getChatbots } from '@/services/hubbot';
import {
  Chatbot,
  CreateChatbotDTO,
  GPTConfig,
  Metadata,
} from '@/services/hubbot/types';
import { updateChatbot } from '@/services/hubbot/update-chatbot';
import theme from '@/theme/mui';

interface UpdateChatbotDrawerProps {
  chatbot: Chatbot;
}

/**
 * @see https://mui.com/material-ui/react-drawer/
 */
export const UpdateChatbotDrawer = ({ chatbot }: UpdateChatbotDrawerProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [open, setOpen] = useState(false);

  const [chatbotUpdate, setChatbotUpdate] = useState<Chatbot>({
    ...chatbot,
  });

  const onFormSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      await updateChatbot(chatbotUpdate);
      await getChatbots();

      setOpen(false);
    } catch (e) {
      console.log(e);
    }

    setIsSubmitting(false);
  };

  const onFormUpdate = (data: Partial<CreateChatbotDTO>) => {
    setChatbotUpdate({
      ...chatbotUpdate,
      ...data,
    });
  };

  const onMetadataUpdate = (data: Partial<Metadata>, workflowName: string) => {
    setChatbotUpdate({
      ...chatbotUpdate,
      workflows: {
        ...chatbotUpdate.workflows,
        [workflowName]: {
          ...chatbotUpdate.workflows[workflowName],
          metadata: {
            ...chatbotUpdate.workflows[workflowName].metadata,
            ...data,
          } as Record<string, string>,
        },
      },
    });
  };

  const onPromptUpdate = (
    type: 'failed' | 'success' | 'unique',
    instructions: string,
    workflowName: string
  ) => {
    setChatbotUpdate({
      ...chatbotUpdate,
      workflows: {
        ...chatbotUpdate.workflows,
        [workflowName]: {
          ...chatbotUpdate.workflows[workflowName],
          context: {
            ...chatbotUpdate.workflows[workflowName].context!,
            [type]: {
              ...chatbotUpdate.workflows[workflowName].context![type],
              instructions,
            },
          },
        },
      },
    });
  };

  const onGPTConfigUpdate = (data: Partial<GPTConfig>) => {
    setChatbotUpdate({
      ...chatbotUpdate,
      gptConfig: {
        ...chatbotUpdate.gptConfig,
        ...data,
      },
    });
  };

  /* istanbul ignore next */
  const openDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={<Typography variant={'caption'}>settings</Typography>}>
        <IconButton aria-label={'settings'} onClick={openDrawer(true)}>
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Drawer
        anchor={'right'}
        open={open}
        onClose={openDrawer(false)}
        PaperProps={{
          sx: { width: '50%' },
        }}
      >
        <Box
          sx={{
            padding: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
          }}
        >
          {/* HEADER */}
          <div
            id="header"
            style={{
              flexShrink: 0,
            }}
          >
            <Stack spacing={1} m={2}>
              <Typography variant={'h2'}>Chatbot</Typography>
            </Stack>
            <Divider />
          </div>

          {/* BODY */}
          <div
            id="content"
            style={{
              flexGrow: '1',
              overflow: 'auto',
              minHeight: '2em',
            }}
          >
            <Stack>
              <UpdateChatbotForm
                chatbot={chatbotUpdate}
                onFormUpdate={onFormUpdate}
                onMetadataUpdate={onMetadataUpdate}
                onPromptUpdate={onPromptUpdate}
                onGPTConfigUpdate={onGPTConfigUpdate}
              />
            </Stack>
          </div>

          {/* FOOTER */}
          <div
            id="footer"
            style={{
              flexShrink: 0,
            }}
          >
            <Divider />

            <Stack
              spacing={1}
              m={2}
              direction="row"
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                variant="outlined"
                disabled={isSubmitting}
                onClick={() => {
                  console.log('onClick');
                  setOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                data-testid={'submit_button'}
                onClick={() => {
                  void onFormSubmit();
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardando...' : 'Guardar cambios'}
              </Button>
            </Stack>
          </div>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};
