import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { useState } from 'react';

import { defaultColDef } from './column-definitions/default-col-def';
import { ChatbotsDataGridToolbar } from './chatbots-data-grid-toolbar.component';

import theme from '@/theme/mui';
import { Chatbot } from '@/services/hubbot/types';

interface ChatbotsDataGridProps {
  chatbots: Chatbot[];
  height: number;
  columnDefinition?: GridColDef[];
}

/**
 * Table component for showing the ContactList
 * It has a client-side pagination and a CSV export button
 * @see https://mui.com/x/react-data-grid/#mit-version
 */
export const ChatbotsDataGrid = ({
  chatbots,
  height,
  columnDefinition = defaultColDef,
}: ChatbotsDataGridProps) => {
  const [pageSize, setPageSize] = useState(25);

  const prepareChatbots = (chatbots: Chatbot[]) => {
    return chatbots.map((chatbot) => {
      return {
        ...chatbot,
        websiteName: chatbot.websites
          .map((website) => {
            return website.name;
          })
          .join('; '),
        websiteId: chatbot.websites
          .map((website) => {
            return website.websiteId;
          })
          .join('; '),
        companyId: chatbot.websites
          .map((website) => {
            return website.companyId;
          })
          .join('; '),
      };
    });
  };

  const preparedChatbots = prepareChatbots(chatbots);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ height: height, width: '100%' }}
        data-testid={'contact_list_data_grid'}
      >
        <DataGrid
          rows={preparedChatbots}
          columns={columnDefinition}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          // disableColumnMenu
          components={{ Toolbar: ChatbotsDataGridToolbar }}
          getRowId={(row: Chatbot) => row.chatbotId}
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 2,
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
};
