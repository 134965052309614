import { extendTheme } from '@chakra-ui/react';

import { inputTheme } from './multipart-theme/input';
import { radioTheme } from './multipart-theme/radio';
import { selectTheme } from './multipart-theme/select';

/**
 * @see https://chakra-ui.com/docs/styled-system/customize-theme
 */
export const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontSize: '14px',
        fontWeight: 'normal',
        textColor: 'gray.600',
      },
    },
  },
  fonts: {
    heading: `'Nunito', sans-serif`,
    body: `'Nunito', sans-serif`,
  },

  textStyles: {
    /** @see https://m2.material.io/design/typography/the-type-system.html#type-scale */
    h1: {
      fontSize: '29px',
      fontWeight: 'semibold',
      textColor: 'gray.600',
    },
    h2: {
      fontSize: '21px',
      fontWeight: 'semibold',
      textColor: 'gray.600',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 'semibold',
      textColor: 'gray.600',
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'normal',
      textColor: 'gray.600',
    },
    p: {
      fontSize: '14px',
      fontWeight: 'normal',
      textColor: 'gray.600',
    },
    cardHeader: {
      fontSize: '14px',
      fontWeight: 'semibold',
      textColor: 'gray.600',
    },
    cardNumber: {
      fontSize: '28px',
      fontWeight: 'normal',
      textColor: 'gray.600',
    },
    formLabel: {
      fontSize: '14px',
      fontWeight: 'semibold',
      textColor: 'gray.600',
    },
  },
  components: {
    Input: inputTheme,
    Select: selectTheme,
    Radio: radioTheme,
    Button: {
      baseStyle: {
        borderRadius: '3xl',
        fontWeight: 'normal',
      },
      variants: {
        primary: {
          bgColor: 'primary.400',
          textColor: 'white',
        },
        secondary: {
          bgColor: 'secondary.400',
          textColor: 'white',
        },
      },
      sizes: {
        md: {
          fontSize: 'sm',
          // px: '6',
          // h: '16',
          // borderRadius: 'md',
        },
      },
      defaultProps: {
        // variant: 'secondary',
        // size: 'md',
      },
    },
    Card: {
      baseStyle: { border: '1px', borderColor: 'gray.200' },
    },
    Table: {
      defaultProps: {
        variant: 'striped',
        colorScheme: 'blackAlpha',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '14px',
        fontWeight: 'semibold',
        textColor: 'gray.600',
      },
    },
    Textarea: {
      defaultProps: {
        size: 'sm',
        variant: 'outline',
      },
    },
  },
  colors: {
    primary: {
      50: '#efe9fd',
      100: '#d4c8fb',
      200: '#b8a4f9',
      300: '#987ef7',
      400: '#7e60f5', // <--
      500: '#6043f2',
      600: '#533eeb',
      700: '#3e36e2',
      800: '#2430db',
      900: '#0025cd',
    },
    secondary: {
      50: '#dcf3ee',
      100: '#aae2d4',
      200: '#6fd0b8',
      300: '#1abc9c', // <--
      400: '#00ad87',
      500: '#009c74',
      600: '#008f68',
      700: '#007f58',
      800: '#006f4b',
      900: '#00522f',
    },
  },
});
