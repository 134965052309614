import { Box, Center, Container, GridItem, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ChatbotsDataGrid } from '@/components/chatbots-data-grid';
import CreateChatbotDrawer from '@/components/create-chatbot-drawer';
import DefaultProgress from '@/components/default-progress';
import Show from '@/components/show';
import { getChatbots } from '@/services/hubbot';
import { getTemplates } from '@/services/hubbot/get-templates';
import { Template } from '@/services/hubbot/types';
import { chatbots } from '@/signals/singals';

/**
 * Parent component that contains the website selector to change content,
 * the list of the existing HSM Tempaltes
 * the button to create a new Template
 */
export const ChatbotsPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [cahtbotsLastUpdate, setChatbotsLastUpdate] = useState(new Date());

  const initData = async (): Promise<void> => {
    setIsLoading(true);

    await getChatbots();
    const templatesResult = await getTemplates();

    setTemplates(templatesResult);
    setIsLoading(false);
  };

  const reloadChatbotTable = async (): Promise<void> => {
    setIsLoading(true);

    await getChatbots();

    setIsLoading(false);
  };

  useEffect(() => {
    void initData();
  }, []);

  useEffect(() => {
    void reloadChatbotTable();
  }, [cahtbotsLastUpdate]);

  const onChatbotCreated = (): void => {
    setChatbotsLastUpdate(new Date());
  };

  return (
    <>
      <Container maxW={'6xl'} padding={'4'}>
        <Show when={isLoading}>
          <DefaultProgress />
        </Show>
        <Show when={!isLoading}>
          <Box>
            <Container maxW={'6xl'} p={4}>
              <SimpleGrid columns={6}>
                <GridItem colSpan={1}>
                  <Box padding={3} textStyle={'h2'}>
                    Chatbots
                  </Box>
                </GridItem>

                <GridItem colSpan={4}></GridItem>
                <GridItem colSpan={1}>
                  <Center>
                    <CreateChatbotDrawer
                      onChatbotCreated={onChatbotCreated}
                      templates={templates}
                    />
                  </Center>
                </GridItem>
              </SimpleGrid>
            </Container>
            <Container maxW={'6xl'} p={4}>
              <ChatbotsDataGrid chatbots={chatbots.value} height={600} />
            </Container>
          </Box>
        </Show>
      </Container>
    </>
  );
};
