import { ExternalLinkIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, HStack, Link, Tooltip } from '@chakra-ui/react';

import Show from '@/components/show';
interface LinkFormProps {
  label: string;
  tooltipLabel: string;
  href: string;
  content: string;
  required: boolean;
}

export const LinkForm = (props: LinkFormProps) => {
  const { label, tooltipLabel, href, content, required, ...additionalProps } =
    props;

  return (
    <Box {...additionalProps}>
      <HStack mb={2} mr={3} spacing={1}>
        <Box textStyle={'formLabel'}>{label}</Box>

        <Tooltip placement={'top-start'} label={tooltipLabel}>
          <QuestionOutlineIcon />
        </Tooltip>

        <Show when={required}>
          <Box textColor={'red'} fontWeight={'semibold'}>
            {'*'}
          </Box>
        </Show>
      </HStack>
      <Link href={href} isExternal>
        {content}
        <ExternalLinkIcon mx="2px" />
      </Link>
    </Box>
  );
};
