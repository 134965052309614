import jwt_decode from 'jwt-decode';

import { instanceOfJwtCliengoPayload, JwtCliengoPayload } from './types';

export function parseJwt(token: string): unknown {
  if (token) {
    return jwt_decode(token);
  }
}

/**
 * Parses a JWT in case it matches the data structure
 * @param token the JWT to parse
 * @returns the parsed token as object
 * @returns undefined if the token does not match the data structure
 */
export function parseJwtCliengo(token: string): JwtCliengoPayload | undefined {
  if (token) {
    const payload = jwt_decode(token);

    if (instanceOfJwtCliengoPayload(payload)) {
      return payload;
    }
  }
}
