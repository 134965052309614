/* eslint-disable @typescript-eslint/no-unused-vars */
import { createChatbotUrl } from './urls';
import { CreateChatbotDTO, CreateChatbotResponseDTO } from './types';

import { HubbotApiHttpClient } from '@/utils/http-client';

export async function createChatbot(
  chatbotBody: CreateChatbotDTO
): Promise<CreateChatbotResponseDTO> {
  const client = HubbotApiHttpClient.instance;

  const response = await client.post<CreateChatbotResponseDTO>(
    createChatbotUrl,
    chatbotBody
  );

  return response;
}
