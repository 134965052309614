import { Box } from '@chakra-ui/react';

interface ReadonlyFormInputProps {
  content: string;
  label?: string;
}

export const ReadonlyFormInput = ({
  content,
  label,
}: ReadonlyFormInputProps) => {
  return (
    <Box>
      {label ? (
        <Box textStyle={'formLabel'} mb={2} mr={3}>
          {label}
        </Box>
      ) : null}
      <Box py={1} px={3} background={'gray.100'}>
        {content}
      </Box>
    </Box>
  );
};
