import { HttpClient } from './http-client';

import { CLIENGO_API_URL } from '@/constants/environment';

export interface CliengoAPIResponse<T> {
  paging: {
    total: number;
  };
  results: T[];
}

/**
 * Singleton HttpClient for CliengoApi communications
 */
export class CliengoApiHttpClient extends HttpClient {
  private static _instance: CliengoApiHttpClient;

  private constructor() {
    super(CLIENGO_API_URL);
  }

  public static get instance() {
    return this._instance || (this._instance = new CliengoApiHttpClient());
  }
}
