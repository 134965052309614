import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

/**
 * @see https://mui.com/x/react-data-grid/export/#custom-toolbar
 */
export function ChatbotsDataGridToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}
