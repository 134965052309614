/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, FormEvent } from 'react';
import {
  Drawer,
  DrawerBody,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerOverlay,
  Divider,
  Text,
} from '@chakra-ui/react';

import { SimulationDTO, newSimulation } from '@/services/hubbot/new-simulation';

interface SimulationFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const SimulationForm: React.FC<SimulationFormProps> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [formData, setFormData] = useState<SimulationDTO>({
    companyId: '',
    websiteId: '',
    buyerPersona: {
      age: 0,
      name: '',
      description: '',
    },
    ammount_topics: 1,
    company: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [parentName, childName] = name.split('.');

    setFormData((prevFormData) => {
      if (
        typeof prevFormData[parentName] === 'object' &&
        prevFormData[parentName] !== null
      ) {
        return {
          ...prevFormData,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [parentName]: {
            ...prevFormData[parentName],
            [childName]: value,
          },
        };
      } else {
        return {
          ...prevFormData,
          [parentName]: value,
        };
      }
    });
  };
  //TO DO arreglar funcion para que sea void o cambiar pq que funcione con lint
  // eslint-disable-next-line react-func/max-lines-per-function
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await newSimulation(formData);

      toast({
        title: 'Simulación creada exitosamente.',
        description: 'Por favor, espere unos minutos para que se complete.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose(); // Cierra el drawer después de enviar el formulario
    } catch (error) {
      toast({
        title: 'Ha ocurrido un error al crear la simulación.',
        description: 'Por favor, intente nuevamente.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Drawer size={'lg'} isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Crea una nueva simulación</DrawerHeader>

        <DrawerBody>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mb={4}>
              <FormLabel>Company ID</FormLabel>
              <Input
                name="companyId"
                placeholder="Ejemplo: 64df8d2e638e7600322bee7c"
                onChange={handleChange}
                value={formData.companyId}
              />
            </FormControl>
            <FormControl isRequired mb={4}>
              <FormLabel>Website ID</FormLabel>
              <Input
                name="websiteId"
                placeholder="Ejemplo: 64ff293a7952600032116c13"
                onChange={handleChange}
                value={formData.websiteId}
              />
            </FormControl>
            <FormControl isRequired mb={4}>
              <FormLabel>Descripción de la empresa</FormLabel>
              <Input
                name="company"
                placeholder="Ejemplo: La empresa se llama Kids y se dedica a la manufactura de prendas de vestir para niños y niñas."
                onChange={handleChange}
                value={formData.company}
              />
            </FormControl>
            <FormControl isRequired mb={4}>
              <FormLabel>
                Cantidad de conversaciones para la simulación
              </FormLabel>
              <Input
                name="ammount_topics"
                type="number"
                onChange={handleChange}
                value={formData.ammount_topics}
              />
            </FormControl>
            <Divider mb={4} borderWidth="1px" />
            <Text fontSize="md" mb={4}>
              Buyer Persona
            </Text>
            <FormControl mb={4} isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input
                name="buyerPersona.name"
                placeholder="Ej: María"
                onChange={handleChange}
                value={formData.buyerPersona.name}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Edad</FormLabel>
              <Input
                name="buyerPersona.age"
                placeholder="45"
                type="number"
                onChange={handleChange}
                value={formData.buyerPersona.age}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Descripción</FormLabel>
              <Input
                name="buyerPersona.description"
                placeholder="Ej: María es una mujer de 45 años que vive en la ciudad de Buenos Aires. Es madre de dos hijos y trabaja como docente en una escuela primaria."
                onChange={handleChange}
                value={formData.buyerPersona.description}
              />
            </FormControl>
            <Button variant={'primary'} type="submit">
              Comenzar simulación
            </Button>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SimulationForm;
