/* eslint-disable @typescript-eslint/no-unused-vars */
import { Chatbot, CreateChatbotDTO } from './types';
import { deleteWebsiteUrl } from './urls';

import { HubbotApiHttpClient } from '@/utils/http-client';
export async function deleteWebsite(website: string): Promise<Chatbot> {
  const client = HubbotApiHttpClient.instance;
  const deleteUrl = `${deleteWebsiteUrl}/${website}`;

  const response = await client.delete<Chatbot>(deleteUrl);

  return response;
}
