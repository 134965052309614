/* eslint-disable @typescript-eslint/no-unused-vars */
import { newSimulationUrl } from './urls';

import { HubbotApiHttpClient } from '@/utils/http-client';
// TO DO Arreglar variables 'any'
export interface BuyerPersona {
  age: number;
  name: string;
  description: string;
}
export interface SimulationDTO {
  companyId: string;
  websiteId: string;
  buyerPersona: BuyerPersona;
  ammount_topics: number;
  company: string; // eslint-disable-next-line
  [key: string]: any;
}
export async function newSimulation(chatbotBody: SimulationDTO) {
  const client = HubbotApiHttpClient.instance;

  const response = await client.post(newSimulationUrl, chatbotBody);

  return response;
}
