import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#4a5568',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#e0e0e0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#e0e0e0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#e0e0e0',
    },
  },
});

export const ReadonlyTextField = (props: TextFieldProps) => {
  return (
    <CssTextField
      {...props}
      InputProps={{
        readOnly: true,
      }}
    />
  );
};
