/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/unbound-method */
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ChatbotsPage from '@/components/chatbots-page';
import FAQsPage from '@/components/faqs-page';
import SimulationsPage from '@/components/simulations-page';
import SimulationReportPAGE from '@/components/simulation-report-page';
import Navbar from '@/components/navbar';
import { ROUTES } from '@/constants/routes';
import authorizationGuard from '@/services/auth/authorization-guard';
import { JwtCliengoPayload } from '@/services/auth/types';
import { UserContext } from '@/services/auth/user-context-provider';

export const App: React.FC = () => {
  const { setJwtCliengoPayload } = useContext(UserContext);

  const onAuthenticationVerified = (jcp: JwtCliengoPayload) => {
    setJwtCliengoPayload(jcp);
  };

  const init = async (): Promise<void> => {
    await authorizationGuard(onAuthenticationVerified);
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path={ROUTES.faqs} element={<FAQsPage />} />
        <Route path={ROUTES.chatbots} element={<ChatbotsPage />} />
        <Route path={ROUTES.simulations} element={<SimulationsPage />} />
        <Route
          path={ROUTES.simulationsDetail}
          element={<SimulationReportPAGE />}
        />
        <Route path="/" element={<Navigate to={ROUTES.chatbots} />} />
        <Route path="/*" element={<Navigate to={ROUTES.chatbots} />} />
      </Routes>
    </>
  );
};
