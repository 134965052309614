/* eslint-disable no-console */
import { Chatbot } from './types';
import { getChatbotsUrl } from './urls';

import { chatbots } from '@/signals/singals';
import { HubbotApiHttpClient } from '@/utils/http-client';

export async function getChatbots(): Promise<Chatbot[]> {
  const client = HubbotApiHttpClient.instance;

  const result = await client.get<Chatbot[]>(getChatbotsUrl);

  chatbots.value = result;

  return result;
}
