/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useState } from 'react';

import { JwtCliengoPayload } from './types';

export interface UserContextType {
  /** JwtPayload object*/
  jwtCliengoPayload?: JwtCliengoPayload;
  /** setState for JwtPayload object*/
  setJwtCliengoPayload(jcp: JwtCliengoPayload): void;
}

/**
 * Context for user information
 * @property jwtCliengoPayload data contained in valid user JWT
 * @property setJwtCliengoPayload: state dipsatcher
 * @property websites: list of websites of the logged user
 * @property setWebsites: state dipsatcher
 */
export const UserContext = createContext<UserContextType>({
  jwtCliengoPayload: undefined,
  setJwtCliengoPayload: () => {},
});

/**
 * UserProvider Component.
 * Component to provide user information to its children
 * @param props children components
 * @returns UserProvider Component
 */
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [jwtCliengoPayload, setJwtCliengoPayload] =
    useState<JwtCliengoPayload>();

  return (
    <UserContext.Provider value={{ jwtCliengoPayload, setJwtCliengoPayload }}>
      {children}
    </UserContext.Provider>
  );
};
