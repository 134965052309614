import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '@fontsource/nunito/400.css';

import { App } from '@/App';
import { UserProvider } from '@/services/auth/user-context-provider';
import { store } from '@/store';
import chakraTheme from '@/theme/chakra';

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <ChakraProvider theme={chakraTheme}>
      <UserProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserProvider>
    </ChakraProvider>
  </Provider>
);
