/* eslint-disable @typescript-eslint/ban-ts-comment */
import Cookies from 'js-cookie';

import { HttpClient } from './http-client';

import { HUBBOT_API_URL, MOCKED_AUTH } from '@/constants/environment';
import { mockedJwt } from '@/__mocks__/services/auth/data';

// export interface HubbotApiResponse<T> {
//   result: T;
//   code: string;
//   statusCode: number;
//   description: string;
// }

// export interface HubbotApiResponseList<T> {
//   result: T[];
//   code: string;
//   statusCode: number;
//   description: string;
// }

/**
 * Singleton HttpClient for Hubbot API communications
 */
export class HubbotApiHttpClient extends HttpClient {
  private static _instance: HubbotApiHttpClient;

  private constructor() {
    super(HUBBOT_API_URL);

    this.setAuthorization(
      // @ts-ignore
      MOCKED_AUTH ? mockedJwt : Cookies.get('jwt')
    );
  }

  public static get instance() {
    return this._instance || (this._instance = new HubbotApiHttpClient());
  }
}
