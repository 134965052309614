/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Image, Spacer, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@/constants/routes';

/**
 * Main Navbar of the application
 * Contains Tabs for navigating to applications pages
 * @see https://chakra-templates.dev/navigation/navbar
 * @see https://chakra-ui.com/docs/components/tabs#controlled-tabs
 */
export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = [ROUTES.chatbots, ROUTES.faqs, ROUTES.simulations];

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    let index = routes.indexOf(location.pathname);

    // if the routes donot contain the pathname, then index is -1
    index = index < 0 ? 0 : index;
    setTabIndex(index);
  }, []);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    navigate(routes[index]);
  };

  return (
    <Box
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={'gray.200'}
      shadow={'lg'}
      py={'0.5'}
    >
      <Flex align={'center'} mx={12}>
        <Flex mx={4} my={3} w={'130px'}>
          <Image
            fit={'contain'}
            src={
              'https://res.cloudinary.com/hbrrdozyj/image/upload/v1558546804/cliengo819x195_zxegqs.png'
            }
          />
        </Flex>
        <Spacer />
        <Flex>
          <Tabs
            variant={'soft-rounded'}
            colorScheme={'primary'}
            mx={4}
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab>Chatbots</Tab>
              <Tab>FAQs</Tab>
            </TabList>
          </Tabs>
        </Flex>
      </Flex>
    </Box>
  );
};
