import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from '@chakra-ui/react';
import Cookies from 'js-cookie';

import { HUBBOT_API_URL, MOCKED_AUTH } from '@/constants/environment';
import { mockedJwt } from '@/__mocks__/services/auth/data';

interface Item {
  id: string;
  title: string;
  type: string;
}
interface selectorProps {
  company: string;
  onChange: (valor: React.ChangeEvent<HTMLSelectElement>) => void;
  setError: (show: boolean, message: string) => void;
}

function WebsiteSelector(props: selectorProps) {
  const { company, onChange, setError } = props;
  const [data, setData] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | ''>('');

  function getWebsitesAPI() {
    const apiUrl = `${HUBBOT_API_URL}/chatbot/${company}/websites`; // TO DO cambiar url a hubbot
    const jwtToken = MOCKED_AUTH ? mockedJwt : Cookies.get('jwt') || '';
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    axios
      .get<Item[]>(apiUrl, axiosConfig)
      .then((response) => {
        const responseData: Item[] = response.data;

        setData(responseData);
      })
      .catch(() => {
        setError(true, 'Error: Verifique el ID ingresado ');
        setData([]);
      });
  }
  // TO DO ARREGLAR ESTE USEEFFECT Hook useEffect has missing dependencies
  useEffect(() => {
    if (company.length == 24) {
      getWebsitesAPI();
      setError(false, '');
    } else {
      if (company === '') {
        setError(false, '');
      } else {
        setError(true, 'Este campo debe contener 24 caracteres de longitud');
        setData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedItem(event.target.value);
    onChange(event);
  };
  const disabled = data.length == 0;

  return (
    <div>
      <Select
        id={'websiteId'}
        value={selectedItem}
        onChange={handleSelectChange}
        disabled={disabled}
      >
        <option value="">Selecciona un website</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title} ( {item.id} ) ({item.type})
          </option>
        ))}
      </Select>
    </div>
  );
}

export default WebsiteSelector;
