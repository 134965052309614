export interface JwtCliengoPayload {
  privileges: string[];
  v: number;
  company: string;
  websites: unknown[];
  user: string;
  timestamp: number;
}

const JwtCliengoPayloadKeys = [
  'privileges',
  'v',
  'company',
  'websites',
  'user',
  'timestamp',
];

/**
 * Returns true if the data is instance of JwtCliengoPayload
 * @param data the object to check
 * @returns true if data contains the keys
 */
export function instanceOfJwtCliengoPayload(
  data: unknown
): data is JwtCliengoPayload {
  for (const key of JwtCliengoPayloadKeys) {
    if (!(key in (data as JwtCliengoPayload))) {
      return false;
    }
  }
  return true;
}
