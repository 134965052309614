import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';

import WebsiteSelector from './create-chatbot-web-selector.component';

import { CreateChatbotDTO, Template } from '@/services/hubbot/types';
import { ReadonlyFormInput } from '@/components/form/chakra';

interface CreateChatbotFormProps {
  chatbot: CreateChatbotDTO;
  templates: Template[];
  onFormUpdate(data: Partial<CreateChatbotDTO>): void;
}
interface errorMessage {
  show: boolean;
  message: string;
}

export const CreateChatbotForm = ({
  chatbot,
  templates,
  onFormUpdate,
}: CreateChatbotFormProps) => {
  const [errorChatbot, seterrorChatbot] = useState<errorMessage>({
    show: false,
    message: ' ',
  });

  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = event.target;
    const formatValue = id === 'chatbotId' ? standardFormat(value) : value;

    onFormUpdate({ [id]: formatValue });
  };
  const standardFormat = (value: string) => {
    return value
      .replace(/ /g, '-')
      .replace(/[^a-z0-9-]/g, '')
      .toLowerCase();
  };

  function errorChatbotId(show: boolean, message: string) {
    seterrorChatbot({
      show: show,
      message: message,
    });
  }

  return (
    <>
      <Stack spacing={'20px'}>
        <FormControl isRequired>
          <FormLabel htmlFor={'templateId'} textStyle={'formLabel'}>
            Template
          </FormLabel>
          <Select
            id={'templateId'}
            data-testid={'template_select'}
            value={chatbot.templateId}
            onChange={onValueChange}
          >
            {templates.map((t) => {
              return (
                <option key={t.templateId} value={t.templateId}>
                  {t.name}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <ReadonlyFormInput
          content={
            templates.find((t) => t.templateId === chatbot.templateId)
              ?.description || ' '
          }
          label="Descripción"
        />

        <FormControl isRequired isInvalid={errorChatbot.show}>
          <FormLabel htmlFor={'companyId'}>companyId</FormLabel>
          <Input
            id={'companyId'}
            data-testid={'company_id_input'}
            value={chatbot.companyId}
            placeholder={'Ej.: 63652bb66449d3002a5f082d'}
            onChange={onValueChange}
          />
          <FormErrorMessage>{errorChatbot.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor={'websiteId'}>websiteId</FormLabel>
          <WebsiteSelector
            company={chatbot.companyId}
            onChange={onValueChange}
            setError={errorChatbotId}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor={'chatbotId'}>chatbot-id</FormLabel>
          <Input
            id={'chatbotId'}
            data-testid={'chatbot_id_input'}
            value={chatbot.chatbotId}
            placeholder={'Ej.: fidelitas'}
            onChange={onValueChange}
          />
        </FormControl>
      </Stack>
    </>
  );
};
