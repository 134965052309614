/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-len */
import Cookies from 'js-cookie';

import { parseJwtCliengo } from './jwt-parser';
import { JwtCliengoPayload } from './types';

import { LOGIN_REDIRECT_URL, MOCKED_AUTH } from '@/constants/environment';
import { CliengoApiHttpClient } from '@/utils/http-client';
import { mockedJwt } from '@/__mocks__/services/auth/data';

/**
 * Redirects to login URL
 */
function redirectToUnauthorized(): void {
  window.location.href = `${LOGIN_REDIRECT_URL}`;
}

/**
 * Validates the authenticity of a Cliengo JWT trying to get the websites.
 * @param jwt the JWT set in the Cookies
 * @param onAuthenticationVerified a Callback to call if the token is correct
 * @returns true in case the token is correct
 */
async function verifyAuthToken(
  jwt: string,
  onAuthenticationVerified: {
    (jcp: JwtCliengoPayload): void;
  }
): Promise<boolean> {
  try {
    const payload: JwtCliengoPayload | undefined = parseJwtCliengo(jwt);

    if (payload) {
      onAuthenticationVerified(payload);
      return Promise.resolve(true);
    }
  } catch (e) {}
  return Promise.resolve(false);
}

/**
 * Sets the JWT for Cliengo-Api calls
 * @param token a valid JWT
 */
function setAuthToken(token: string): void {
  const client = CliengoApiHttpClient.instance;

  client.setAuthorization(token);
}

/**
 * Verify it the JWT in cookies is valid.
 * Sets the credentials for Cliengo-API calls in case of valid JWT.
 * Redirects to login in case of invalid JWT.
 * @param onAuthenticationVerified a Callback to call when the token is correct
 */
async function authorizationGuard(onAuthenticationVerified: {
  (jcp: JwtCliengoPayload): void;
}): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

  const jwt: string | undefined = MOCKED_AUTH ? mockedJwt : Cookies.get('jwt');

  if (!jwt) {
    redirectToUnauthorized();
    return;
  }

  const result = await verifyAuthToken(jwt, onAuthenticationVerified);

  if (result) {
    setAuthToken(jwt);
    return;
  }
  redirectToUnauthorized();
}

export default authorizationGuard;
