import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Tag,
  Stat,
  StatLabel,
  StatNumber,
  Input,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';

import { Simulation, SimulationResponse } from './simulation.types';

const SimulationList = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const navigate = useNavigate();
  const [simulations, setSimulations] = useState<Simulation[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    fetch('https://workflow-platform.devcliengo.com/webhook/hubbot/simulation')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data: SimulationResponse) => {
        setSimulations(data.data);
      })
      .catch(() => {
        toast({
          title: 'Error fetching simulations',
          description: "Couldn't fetch the simulations from the server",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const companyId = event.target.value;

    setSearchTerm(companyId);
    if (companyId) {
      fetch(`/api/simulations/search?companyId=${companyId}`)
        .then((response) => response.json())
        .then((data: Simulation[]) => setSimulations(data))
        .catch(() => {
          toast({
            title: 'Error searching simulations',
            description: "Couldn't find any simulation with that company ID",
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const detailSimumulation = (id: string) => {
    navigate(`/simulations/${id}`);
  };

  return (
    <>
      <Input
        placeholder="Search by Company ID..."
        mb={4}
        value={searchTerm}
        onChange={handleSearch}
      />
      <VStack spacing={4} align="stretch">
        {simulations.map((simulation) => (
          <Box key={simulation._id} p={5} shadow="md" borderWidth="1px">
            <HStack spacing={4} justify="space-between">
              <VStack align="stretch">
                <Heading fontSize="xl">
                  {simulation.buyerPersona.name}, Age:{' '}
                  {simulation.buyerPersona.age}
                </Heading>
                <Text fontSize="md"> companyId: {simulation.companyId}</Text>
                <Text fontSize="sm">{simulation.buyerPersona.description}</Text>
                <Text fontSize="sm" opacity={0.7}>
                  {new Date(simulation.created_at).toLocaleDateString()}
                </Text>
              </VStack>
              <Stat>
                <StatLabel>Conversaciones</StatLabel>
                <StatNumber>{simulation.conversations.length}</StatNumber>
              </Stat>
              <Tag
                size="sm"
                colorScheme={
                  simulation.status === 'COMPLETED'
                    ? 'green'
                    : simulation.status === 'PENDING'
                    ? 'yellow'
                    : 'red'
                }
              >
                {simulation.status}
              </Tag>
              <IconButton
                aria-label="Detalles de la simulación"
                icon={<ViewIcon />}
                onClick={() => detailSimumulation(simulation._id)}
              />
            </HStack>
            <Text mt={4}>Descripción de la empresa: {simulation.company}</Text>
          </Box>
        ))}
      </VStack>
    </>
  );
};

export default SimulationList;
