/* eslint-disable @typescript-eslint/no-unused-vars */
import { Chatbot, CreateChatbotDTO } from './types';
import { updateChatbotUrl } from './urls';

import { HubbotApiHttpClient } from '@/utils/http-client';

export async function updateChatbot(
  chatbotBody: Partial<CreateChatbotDTO>
): Promise<Chatbot> {
  const client = HubbotApiHttpClient.instance;

  const response = await client.put<Chatbot>(updateChatbotUrl, chatbotBody);

  return response;
}
