/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Divider,
  FormControl,
  FormLabel,
  OutlinedInput,
  Typography,
  Box,
  Switch,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';

import { ReadonlyTextField } from '@/components/form/mui';
import { Chatbot, GPTConfig, Metadata, Website } from '@/services/hubbot/types';

interface UpdateChatbotFormProps {
  chatbot: Chatbot;
  onFormUpdate(data: Partial<Chatbot>): void;
  onMetadataUpdate(data: Partial<Metadata>, workflowName: string): void;
  onPromptUpdate(
    type: string,
    instructions: string,
    workflowName: string
  ): void;
  onGPTConfigUpdate(data: Partial<GPTConfig>): void;
}

export const UpdateChatbotForm = ({
  chatbot,
  onMetadataUpdate,
  onPromptUpdate,
  onGPTConfigUpdate,
  onFormUpdate,
}: UpdateChatbotFormProps) => {
  const workflowNames: string[] = Object.keys(chatbot.workflows);
  const [switchValue, setSwitchValue] = useState<boolean>(
    chatbot.inProd ? true : false
  );

  if (chatbot.inProd === undefined) {
    onFormUpdate({ inProd: false });
  }
  const onMetadataChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    workflowName: string
  ) => {
    const { id, value } = event.target;

    onMetadataUpdate({ [id]: value }, workflowName);
  };

  const onInstructionsChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    type: 'success' | 'failed' | 'unique',
    workflowName: string
  ) => {
    const { value } = event.target;

    onPromptUpdate(type, value, workflowName);
  };

  const onGptConfigChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = event.target;

    onGPTConfigUpdate({ [id]: value });
  };

  const onChabotChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (event.target instanceof HTMLInputElement) {
      const { id, checked } = event.target;

      setSwitchValue(checked);
      onFormUpdate({ [id]: checked });
    }
  };
  const SKIPED_ATTRS = ['provider'];

  return (
    <>
      <Stack spacing={2} m={3}>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="nowrap"
          width={'100%'}
        >
          <FormControl sx={{ width: '80%' }}>
            <FormLabel htmlFor={'chatbotId'}>chatbot-id</FormLabel>
            <ReadonlyTextField value={chatbot.chatbotId} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={'inProd'}>Producción</FormLabel>
            <Switch
              id={'inProd'}
              onChange={onChabotChange}
              checked={switchValue}
            />
          </FormControl>
        </Stack>

        <FormControl>
          <FormLabel htmlFor={'websiteIds'}>websites</FormLabel>
          <ReadonlyTextField
            value={chatbot.websites.map((w: Website) => w.websiteId).join(', ')}
            sx={{ minWidth: '16rem' }}
          />
        </FormControl>

        <Stack spacing={1} m={2}>
          <Typography variant={'h3'}>Personalización (Metadata)</Typography>
        </Stack>

        {workflowNames.map((workflowName) => {
          if (chatbot.workflows[workflowName]) {
            return (
              <Box key={workflowName + '_form'}>
                <Stack spacing={1} mb={1}>
                  <Typography variant={'h4'}>
                    Workflow: {workflowName}
                  </Typography>
                </Stack>
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {Object.keys(chatbot.workflows[workflowName].metadata).map(
                    (key) => (
                      <FormControl key={key}>
                        <FormLabel htmlFor={key}>{key} </FormLabel>
                        <OutlinedInput
                          id={key}
                          data-testid={`${key}_input`}
                          sx={{ minWidth: '16rem' }}
                          value={chatbot.workflows[workflowName].metadata[key]}
                          onChange={(e) => {
                            onMetadataChange(e, workflowName);
                          }}
                        />
                      </FormControl>
                    )
                  )}
                </Stack>

                <Stack spacing={1} my={2}>
                  <Typography variant={'h5'}>
                    Instrucciones (Prompts)
                  </Typography>
                </Stack>

                {!!chatbot.workflows[workflowName].context ? (
                  <Stack spacing={2}>
                    {!!chatbot.workflows[workflowName].context?.success ? (
                      <FormControl>
                        <FormLabel htmlFor={'success'}>
                          Success Prompt
                        </FormLabel>
                        <OutlinedInput
                          id={'success'}
                          multiline
                          data-testid={`success_input`}
                          value={
                            chatbot.workflows[workflowName].context!.success!
                              .instructions
                          }
                          onChange={(e) =>
                            onInstructionsChange(e, 'success', workflowName)
                          }
                        />
                      </FormControl>
                    ) : null}
                    {!!chatbot.workflows[workflowName].context?.failed ? (
                      <FormControl>
                        <FormLabel htmlFor={'failed'}>Failed Prompt</FormLabel>
                        <OutlinedInput
                          id={'failed'}
                          multiline
                          data-testid={`failed_input`}
                          value={
                            chatbot.workflows[workflowName].context!.failed!
                              .instructions
                          }
                          onChange={(e) =>
                            onInstructionsChange(e, 'failed', workflowName)
                          }
                        />
                      </FormControl>
                    ) : null}
                    {!!chatbot.workflows[workflowName].context?.unique ? (
                      <FormControl>
                        <FormLabel htmlFor={'unique'}>Unique Prompt</FormLabel>
                        <OutlinedInput
                          id={'unique'}
                          multiline
                          data-testid={`unique_input`}
                          value={
                            chatbot.workflows[workflowName].context!.unique!
                              .instructions
                          }
                          onChange={(e) =>
                            onInstructionsChange(e, 'unique', workflowName)
                          }
                        />
                      </FormControl>
                    ) : null}
                  </Stack>
                ) : null}
                <Box my={2}>
                  <Divider />
                </Box>
              </Box>
            );
          }
          return null;
        })}

        <Stack spacing={1} m={2}>
          <Typography variant={'h3'}>
            Configuración avanzada (GPT Config)
          </Typography>
        </Stack>

        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {Object.keys(chatbot.gptConfig).map((key: string) => {
            if (SKIPED_ATTRS.includes(key)) {
              return null;
            }

            return (
              <FormControl key={key}>
                <FormLabel htmlFor={key}>{key} </FormLabel>
                <OutlinedInput
                  sx={{ minWidth: '16rem' }}
                  id={key}
                  fullWidth
                  data-testid={`${key}_input`}
                  // @ts-ignore
                  value={chatbot.gptConfig[key]}
                  onChange={onGptConfigChange}
                />
              </FormControl>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};
