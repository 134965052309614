/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import { deleteWebsite } from '@/services/hubbot/delete-website';
import { getChatbots } from '@/services/hubbot';
import { Chatbot } from '@/services/hubbot/types';

interface ListChatbotDrawerProps {
  chatbot: Chatbot;
  closeDrawer: () => void;
}

export const ListWebsites = ({
  chatbot,
  closeDrawer,
}: ListChatbotDrawerProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [webToDelete, setWebToDelete] = useState('');

  const onDelete = async (): Promise<void> => {
    //setIsSubmitting(true);
    try {
      await deleteWebsite(webToDelete);
      await getChatbots();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }

    //setIsSubmitting(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack spacing={3} m={2}>
      <List>
        {chatbot.websites.map((website, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                color="warning"
                aria-label="delete"
                onClick={() => {
                  handleOpenDialog();
                  setWebToDelete(website.websiteId);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <WebAssetIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ variant: 'h3' }}
              primary={`Website: ${website.websiteId}`}
              secondary={`Company: ${website.websiteId}`}
            />
          </ListItem>
        ))}
      </List>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" alignItems="center" p={3}>
            <DeleteIcon style={{ marginRight: '8px' }} color="warning" />
            <Typography variant={'h2'}>
              {'¿Está seguro que desea desasignar este Website?'}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={'h3'}>
              Al desasignar este website el bot ya no responderá con IA
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              void onDelete();
              handleCloseDialog();
            }}
          >
            Desasignar
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
