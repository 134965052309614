import { createTheme } from '@mui/material';
import { esES as dataGridesES } from '@mui/x-data-grid';
import { esES } from '@mui/material/locale';

/**
 * @see https://mui.com/material-ui/customization/theming/
 */
export const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#7e60f5',
      },
      secondary: {
        main: '#1abc9c',
      },
    },
    typography: {
      /** @see https://m2.material.io/design/typography/the-type-system.html#type-scale */
      fontFamily: ['Nunito', 'sans-serif'].join(','),
      h1: {
        fontSize: '29px',
        fontWeight: 'bold',
        color: '#4a5568',
      },
      h2: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#4a5568',
      },
      h3: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#4a5568',
      },
      h4: {
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#4a5568',
      },
      h5: {
        fontSize: '15px',
        fontWeight: 'normal',
        color: '#4a5568',
      },
      body1: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#4a5568',
      },
      caption: {
        fontSize: '14px',
        fontWeight: 'normal',
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            color: '#4a5568',
          },
          columnHeaderTitle: {
            fontWeight: 'bold',
            fontSize: '13px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: '#4a5568',
            fontSize: '14px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: '#4a5568',
            borderRadius: 2,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 25,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  esES,
  dataGridesES
);
